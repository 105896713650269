<template>
  <div class="w-100">
    <v-chart
      :options="options"
      :auto-resize="true"
      :style="'height: 250px; width: 100%; max-width: 100%'"
    ></v-chart>
  </div>
</template>
<script>
export default {
  name: "PieChart",
  props: {
    analytics: {
      default: () => []
    },
    legend: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    options() {
      return {
        responsive: true,
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%) Followers"
        },
        legend: {
          data: this.legend
        },
        series: [
          {
            type: "pie",
            radius: "55%",
            data: this.analytics,
            label: false,
            labelLine: false,
            animationEasing: "elasticOut",
            animationDelay: () => {
              return Math.random() * 200;
            }
          }
        ]
      };
    }
  }
};
</script>
